<template>

<div class="videos" :class="{'is-single': block.videos.length === 1}">

	<div class="videos-item" v-for="(video, index) in block.videos" :key="index">

		<iframe height="315" :src="videoUrl(video.url)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

	</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	methods: {

		videoUrl: function(url) {

			return url.replace('https://youtu.be/', 'https://www.youtube.com/embed/')

		}

	}

}

</script>

<style scoped>

.videos {
	display: flex;
	flex-wrap: wrap;
}

.videos-item {
	width: calc(50% - 10px);
	margin-bottom: 20px;
}

.is-mobile .videos-item {
	width: 100%;
}

.videos.is-single .videos-item {
	width: 100%;
}

.videos-item:nth-child(2n) {
	margin-left: 20px;
}

.is-mobile .videos-item:nth-child(2n) {
	margin-left: 0px;
}

.videos-item iframe {
	width: 100%;
}

</style>
